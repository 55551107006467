import { render, staticRenderFns } from "./cgzpsecond.vue?vue&type=template&id=0cfe8846&scoped=true&"
import script from "./cgzpsecond.vue?vue&type=script&lang=js&"
export * from "./cgzpsecond.vue?vue&type=script&lang=js&"
import style0 from "./cgzpsecond.vue?vue&type=style&index=0&id=0cfe8846&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cfe8846",
  null
  
)

export default component.exports