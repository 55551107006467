<template>
  <div class="bigDiv">
    <optionscard :deliverParentNum="3"></optionscard>
    <breadcrumb></breadcrumb>
    <div class="width1200">
      <!-- 上 （一张图  “展品分类几”） -->
      <div class="up-picture">
        <img class="imagePic" :src="imageOne" width="100%" height="100%" />
        <div class="imageName">{{ imageName }}</div>
      </div>
      <!-- 9个图片+名字div -->
      <div class="middle-picture">
        <div
          class="middle-pictures"
          v-for="item in exhibitsList"
          :key="item.id"
          @click="getDetails(item.id)"
        >
          <!-- 上面的图 -->
          <div class="middle-picture-div">
            <img :src="item.image" width="100%" height="100%" />
          </div>
          <!-- 下面的标题名字 -->
          <div class="picture-name">
            {{ item.title }}
          </div>
        </div>
      </div>
      <!-- 分页 -->
      <el-pagination
        class="elPagination3"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="venueexhibits.page"
        :page-size="venueexhibits.paginate"
        layout="total,slot, prev, pager, next, jumper"
        :total="total"
      >
        <span class="paginationSpan"> 记录 </span>
      </el-pagination>
    </div>
  </div>
</template>

<script>
import breadcrumb from '@/components/Breadcrumb.vue'
import optionscard from '@/components/optionscard.vue'
export default {
  components: {
    // 头部选项卡（首页 场馆展品 场馆活动 地震知识 参观服务
    optionscard,
    breadcrumb
  },
  data () {
    return {
      // 获取数据(下面9个图)的参数
      venueexhibits: {
        cate_id: '',
        pid: 3,
        paginate: 9,
        page: 1
      },
      // 存放数据(下面9个图)
      exhibitsList: [],
      // 上 （一张图  “展品分类几”）
      imageOne: '',
      imageName: '',
      total: 0
    }
  },
  created () {
    // JSON 通常用于与服务端交换数据。在接收服务器数据时一般是字符串。我们可以使用 JSON.parse() 方法将数据转换为 JavaScript 对象。
    const data = JSON.parse(this.$route.query.data)
    console.log('data', data)

    this.venueexhibits.cate_id = data.category_id
    console.log('this.venueexhibits', this.venueexhibits)
    this.imageOne = data.image
    this.imageName = data.name
    // console.log('cateId', cateId)
    // console.log(this.$route.query)
    this.getExhibits()
  },
  methods: {
    // 获取数据(下面9个图)
    async getExhibits () {
      const { res } = await this.$api.venueexhibits.getExhibits(
        this.venueexhibits
      )
      console.log('res', res)
      for (const i of res.data) {
        // 标题字太多给... 只显示一行
        if (i.title.length > 10) {
          i.title = i.title.slice(0, 10) + '...'
        }
      }
      this.exhibitsList = res.data
      console.log('this.exhibitsList', this.exhibitsList)
      this.total = res.total
    },
    // 每页几条
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
      this.venueexhibits.paginate = val
      this.getExhibits()
    },
    // 当前第几页
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
      this.venueexhibits.page = val
      this.getExhibits()
    },
    // 跳转
    getDetails (id) {
      console.log('id', id)
      this.$router.push({
        path: '/third/cgzpfirst/cgzpsecond/cgzpthird',
        query: { id }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.bigDiv {
  height: 1500px;
}

.width1200 {
  padding-top: 20px;
  width: 1200px;
  margin: 0 auto;
}

.up-picture {
  width: 1200px;
  height: 250px;
  position: relative;
}

.imagePic {
  object-fit: cover;
}

.imageName {
  position: absolute;
  top: 45%;
  left: 45%;
  color: #fff;
  line-height: 26px;
  font-size: 33px;
}

.middle-picture {
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
  justify-content: flex-start;
}

.picture-name {
  margin-top: 10px;
  margin:0 auto;
  text-align: center;
  font-size: 20px;
  border: 1px solid #eaeaea;
  padding: 20px 0;
  color: #333333;
}

.middle-pictures:hover .picture-name{
  color: #2e88e4;
}

.middle-pictures {
  width: 383px;
  margin-bottom: 50px;
  margin-left: 17px;
  cursor: pointer;
}

.middle-picture-div {
  margin-right: 17px;
  width: 383px;
  height: 250px;
}

.down-picture {
  display: flex;
  justify-content: space-between;
  margin-top: 100px;
}

.elPagination3 {
  float: right;
  padding: 2px 5px 40px;
}

.paginationSpan {
  margin-right: -10px;
  font-weight: normal;
  color: #606060;
}
</style>
